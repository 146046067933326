import * as React from "react";

import { graphql } from "gatsby";

import HygieneTemplate from "../templates/hygienePage";

const HygieneWithData = ({ data }) => {
  return <HygieneTemplate data={data} />;
};

export const hygienePageQuery = graphql`
  query ($uid: String) {
    prismicHygienePage(uid: { eq: $uid }) {
      data {
        meta_title
        meta_description
        content {
          html
          richText
        }
      }
    }
  }
`;

export default HygieneWithData;
