import * as React from "react";
import { Layout } from "../components/Layout";
import { PrismicRichText } from "@prismicio/react";

const HygieneTemplate = ({ data }) => {

  return (
    <Layout
      metaTitle={data.prismicHygienePage.data.meta_title}
      metaDescription={data.prismicHygienePage.data.meta_description}
    >
      <div className="h-6 md:h-10 bg-teal-alpha-30 mb-6 md:mb-10"></div>

      <div className="hygiene-page container text-base">
        <PrismicRichText
          field={data.prismicHygienePage.data.content.richText}
        />
      </div>
    </Layout>
  );
};

export default HygieneTemplate;
